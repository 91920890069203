<div id="carouselFrontpageCaptions" class="carousel slide" data-ride="carousel" touch="true">
    <ol class="carousel-indicators">
        <li data-target="#carouselFrontpageCaptions" data-slide-to="0" class="active"></li>
        <li data-target="#carouselFrontpageCaptions" data-slide-to="1"></li>
        <li data-target="#carouselFrontpageCaptions" data-slide-to="2"></li>
        <li data-target="#carouselFrontpageCaptions" data-slide-to="3"></li>
    </ol>
    <div class="carousel-inner">
        <div class="carousel-item" *ngFor="let item of featured; let isFirst = first" [class.active]="isFirst">
            <a routerLink="/item/{{ item.link }}"><img src="{{ item.image }}" class="d-block w-100" alt="{{ item.title }}" data-interval="2200"></a>
            <div class="carousel-caption darkened d-none d-md-block">
                <h4>{{ item.title }}</h4>
                <p>{{ item.description }}</p>
            </div>
        </div>
    </div>

    <a class="carousel-control-prev" href="#carouselFrontpageCaptions" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselFrontpageCaptions" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
</div>