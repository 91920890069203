<footer class="text-light p-5">
    <div class="row">
        <div class="col-lg-3 col-sm-6">
            <h4 class="my-4">About ADAM</h4>
            <p>The <strong>Archive of Demo Art & Media</strong> at <a href="https://demoarchive.art">demoarchive.art</a> is a project by <strong>Marin Balabanov</strong> as part of the university program <a href="https://www.donau-uni.ac.at/de/studium/mediaarthistories.html" target="_blank">MediaArtHistories</a> at the <a href="https://www.donau-uni.ac.at/" target="_blank">Danube University Krems.</a></p><p>This public database charts the development of demo art in its various manifestations from the latter half of the 20<sup>th</sup> century onwards.</p>
        </div>
        <div class="col-lg-3 col-sm-6">
            <h4 class="my-4">Quick Links</h4>
            <ul>
                <li class="my-2"><a routerLink="/" class="text-white">Home</a></li>
                <li class="my-2"><a routerLink="/archive" class="text-white">Archive Categories</a></li>
                <li class="my-2"><a routerLink="/category/artifacts" class="text-white">Artifacts</a></li>
                <li class="my-2"><a routerLink="/category/persons" class="text-white">Persons</a></li>
                <li class="my-2"><a routerLink="/category/events" class="text-white">Events</a></li>
                <li class="my-2"><a routerLink="/news" class="text-white">News</a></li>
            </ul>
        </div>
        <div class="col-lg-3 col-sm-6">
            <h4 class="my-4">Compliance Links</h4>
			<ul>
                <li class="my-2"><a routerLink="/compliance/about" class="text-white">About</a></li>
                <li class="my-2"><a routerLink="/contact" class="text-white">Contact</a></li>
                <li class="my-2"><a routerLink="/compliance/imprint" class="text-white">Legal Imprint</a></li>
                <li class="my-2"><a routerLink="/compliance/terms" class="text-white">Terms of Use</a></li>
                <li class="my-2"><a routerLink="/compliance/privacy" class="text-white">Privacy Policy</a></li>
                <li class="my-2"><a routerLink="/compliance/cookies" class="text-white">Cookie Policy</a></li>
            </ul>
        </div>
        <div class="col-lg-3 col-sm-6">
            <h4 class="my-4">License</h4>
            <p><a rel="license" href="http://creativecommons.org/licenses/by/4.0/"><img alt="Creative Commons Licence" class="border border-white mb-2" src="https://i.creativecommons.org/l/by/4.0/88x31.png" /></a><br />The source code of this work is licensed under a <a rel="license" href="http://creativecommons.org/licenses/by/4.0/">Creative Commons Attribution 4.0 International License.</a></p>
            <p>All images and photos are currently only placeholders licensed from <a href="https://stock.adobe.com" target="_blank">stock.adobe.com</a></p>
        </div>
    </div>
    <div class="row my-4 border-top">
        <div class="col-12 pt-4">
            Copyright © {{ copyrightYear }} ADAM - Archive of Demo Art & Media. For more technical infos, please visit <a href="https://mbalabanov.github.io/adam" target="_blank" class="text-primary">mbalabanov.github.io/adam</a>
        </div>
    </div>
</footer>
