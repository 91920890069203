
<!-- This is the archive application's FRONT PAGE.
     It provides users with an introduction to the Archive's purpose (static information),
     shows a carousel of featured items (images, text, linked to the item detail pages),
     provides a search in the navbar and in the archive categories, and features an
     aggregated list of the news.

     PAGE STRUCTURE
     ┌──────────┐ 
     │   Home   │ 1. navbar.component with search and login (uses auth.service for
     │   Page   │    login, sends search queries to search page).
     └──────────┘ 2. carousel.component showing featured items (uses
                     apirequests.service to GET featured items).
                  3. Static information on purpose of archive.
                  4. archivecategories.component with search and links to the three
                     categories (uses apirequests.service to GET category infos).
                  5. Static information on how to contribute.
                  6. newslist.component showing current news stories (uses
                     apirequests.service to GET news items).
                  7. footer.component.
                  8. cookiewarning.component. -->

<!-- Angular Universal SSR should only render the navbar on the client side, because the authentication
     through Auth0.com (login/register in the navbar) requires the DOM. The server does not have a DOM. -->

<app-navbar *ngIf="isBrowser"></app-navbar>

<!-- Featured items -->
<app-carousel></app-carousel>

<!-- Description of the archive's purpose -->
<div class="row my-md-2 py-3 mt-2 px-3">
    <div class="col-8 offset-2 pr-3 text-center">
        <h1>Archive of Demo Art & Media</h1>
        <p>This database of digital art charts contemporary and historic demo art in its various manifestations (artists, events, works, etc.) from the latter half of the 20<sup>th</sup> century onwards.</p>
    </div>
</div>

<!-- Search and archive categories -->
<div class="row choices">
    <div class="container">
	<app-archivecategories></app-archivecategories>
    </div>
</div>

<!-- Call for contributions -->
<div class="container">
    <div class="row pt-4 mt-2">
        <div class="col-12 text-center">
            <div class="row pt-2">
                <div class="col-12 text-center">
                    <h2>Contribute to ADAM</h2>
                </div>
            </div>
            <div class="row px-5 pt-3 pb-5">
                <div class="col-lg-4 col-sm-6 text-center px-5 d-flex flex-column">
                    <div class="mx-5">
                        <img src="assets/img/contribute1.jpg" alt="Contribute to ADAM 1" class="py-2 img-fluid rounded-circle">
                    </div>
                    <h3 class="py-2">Register as an Editor</h3>
                    <p>You can now access the brand-new editor's area at ADAM, the Archive of Demo Art & Media, by simply clicking on "Login" on the top of the page.</p>
                </div>
                <div class="col-lg-4 col-sm-6 text-center px-5 d-flex flex-column">
                    <div class="mx-5">
                        <img src="assets/img/contribute2.jpg" alt="Contribute to ADAM 2" class="py-2 img-fluid rounded-circle">
                    </div>
                    <h3 class="py-2">Edit and Create Articles</h3>
                    <p>You can join the community and edit existing articles or create new ones. Contribute to documenting the rapidly evolving field of demo art.</p>
                </div>
                <div class="col-lg-4 col-sm-6 text-center px-5 d-flex flex-column">
                    <div class="mx-5">
                        <img src="assets/img/contribute3.jpg" alt="Contribute to ADAM 3" class="py-2 img-fluid rounded-circle">
                    </div>
                    <h3 class="py-2">Social Media Login</h3>
                    <p>You can register with ADAM using your email address, or if you don't want additional user credentials, simply use your existing account at Google to login.</p>

                </div>
            </div>
        </div>
    </div>
    <div class="row py-2 mb-2">
        <div class="col-md-10 offset-md-1 text-center">
            <h2>ADAM Explained</h2>
            <p>Learn more about the demoscene and the role of the Archive of Demo Art & Media in preserving this new media artform of computer demos.</p>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/pwi1-JSts-Q" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    </div>
</div>

<!-- Separator content -->
<div class="row p-3 text-white" id="features">
    <div class="col-12 mt-3 py-4 text-center">
        <h4>At the Cross section of Art & Technology</h4>
        <h3>Adventures in Demo Art</h3>
        <a class="btn btn-light mt-4" routerLink="/archive">Browse Archive</a>
    </div>
</div>

<!-- List of news items -->
<div class="container">
	<app-newslist></app-newslist>
</div>

<app-footer></app-footer>
<app-cookiewarning></app-cookiewarning>
