<h3>Contact Form</h3>
<div class="alert alert-danger" role="alert">Please note that this contact form is not yet operational.</div>
<form>
  <div class="form-group">
    <label for="userName">Name</label>
    <input type="text" class="form-control" id="userName" aria-describedby="emailHelp">
  </div>
  <div class="form-group">
    <label for="userEmail">Email address</label>
    <input type="email" class="form-control" id="userEmail" aria-describedby="emailHelp">
  </div>
  <div class="form-group">
    <label for="userMessage">Message</label>
    <textarea name="usermessage" class="form-control" id="userMessage" aria-describedby="emailHelp"></textarea>
  </div>
  <input type="submit" class="btn btn-outline-secondary" value="submit">
</form>