<h3>Edit the News Articles</h3>
<img src="assets/img/edititems-edit-news.jpg"data-toggle="modal" data-target="#editNews" alt="Edit the News Articles" class="img-fluid border border-primary">
<p>Edit any of the three news articles and upload images.</p>
<p><button class="btn btn-outline-secondary" data-toggle="modal" data-target="#editNews">Edit News</button></p>

<!-- Modal for editing News -->
<div class="modal fade" id="editNews" tabindex="-1" aria-labelledby="editNews" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="editNewsLabel">Edit News</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form>
                    <h3>News</h3>

                    <div [formGroup]="newsEdited" class="form-group" *ngFor="let item of newsItemsArray, let i = index">
                        <h4>News Article: "{{ item.title }}" </h4>
                        <div formGroupName="news{{ i }}" class="form-group alert alert-secondary">
                            <div class="mt-2 input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text"><strong>Item Id:</strong></div>
                                </div>
                                <input type="text" formControlName="_id" class="form-control" readonly>
                            </div>
                            <label class="mt-2"><strong>Unique Name:</strong></label>
                            <input type="text" formControlName="uniquename" class="form-control" required>
                            <label class="mt-2"><strong>Title:</strong></label>
                            <input type="text" formControlName="title" class="form-control" required>
                            <label class="mt-2"><strong>Thumbnail Image:</strong></label>
                            <input type="text" formControlName="image" class="form-control" required>
                            <label class="mt-2"><strong>Large Image:</strong></label>
                            <input type="text" formControlName="largeimage" class="form-control" required>
                            <label class="mt-2"><strong>Short Description:</strong></label>
                            <input type="text" formControlName="shortdescription" class="form-control" required>
                            <label class="mt-2"><strong>Article Text:</strong></label>
                            <editor formControlName="articletext"
                                apiKey="kzw0zdrheylzt0ms8wq28xfgawoi9ltzt8n4t76a42uphen2"
                                [init]="{
                                    height: 500,
                                    menubar: false,
                                    plugins: [
                                    'advlist autolink lists link image charmap print',
                                    'preview anchor searchreplace visualblocks code',
                                    'fullscreen insertdatetime media table paste',
                                    'help wordcount'
                                    ],
                                    toolbar:
                                    'undo redo | formatselect | bold italic | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist outdent indent | help'
                                }"
                                >
                            </editor>
                        </div>
                    </div>
    

                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" [disabled]="newsEdited.invalid" (click)="saveNews()" data-dismiss="modal">Save changes</button>
                <span *ngIf="newsEdited.invalid" class="badge badge-pill badge-danger px-3 py-1">Data<br>incomplete</span>
            </div>
        </div>
    </div>
</div>