<h3>Create an Archive Item</h3>
<img src="assets/img/edititems-create-archive-item.jpg" data-toggle="modal" data-target="#newArchiveItem" alt="Create a New Archive Items" class="img-fluid border border-primary">
<p>Create a new item in the archive (artifact, person, or event).</p>
<p><button class="btn btn-outline-secondary" data-toggle="modal" data-target="#newArchiveItem">Create New Archive Item</button></p>

<!-- Modal for Creating New Archive Items -->
<div class="modal fade" id="newArchiveItem" tabindex="-1" aria-labelledby="newArchiveItem" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newArchiveItemLabel">Create new Archive Item</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div [formGroup]="createdArchiveItem" class="form-group">
                    <label class="mt-2"><strong>Category:</strong></label>
                    <select formControlName="category" class="form-control">
                        <option selected value="artifacts">artifacts</option>
                        <option value="persons">persons</option>
                        <option value="events">events</option>
                    </select>
                    <label class="mt-2"><strong>Name:&nbsp;</strong></label><span *ngIf="name.invalid" class="badge badge-pill badge-warning">Required</span>
                    <input type="text" formControlName="name" class="form-control">
                    <label class="mt-2"><strong>Aliases:</strong></label>
                    <input type="text" formControlName="aliases" class="form-control">
                    <label class="mt-2"><strong>Short Description:&nbsp;</strong></label><span *ngIf="shortdescription.invalid" class="badge badge-pill badge-warning">Required</span>
                    <input type="text" formControlName="shortdescription" class="form-control">
                    <label class="mt-2"><strong>Long Description:&nbsp;</strong></label><span *ngIf="longdescription.invalid" class="badge badge-pill badge-warning">Required</span>
                    <editor formControlName="longdescription"
                        apiKey="kzw0zdrheylzt0ms8wq28xfgawoi9ltzt8n4t76a42uphen2"
                        [init]="{
                            height: 500,
                            menubar: false,
                            plugins: [
                            'advlist autolink lists link image charmap print',
                            'preview anchor searchreplace visualblocks code',
                            'fullscreen insertdatetime media table paste',
                            'help wordcount'
                            ],
                            toolbar:
                            'undo redo | formatselect | bold italic | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | help'
                        }"
                        >
                    </editor>
                    <label class="mt-2"><strong>Dates:</strong></label>
                    <div formGroupName="date0" class="form-inline alert alert-secondary">
                        <label for="datelabel" class="mr-2"><strong>First Date Label:</strong></label>
                        <input type="text" formControlName="label" id="datelabel" class="mr-2">
                        <label class="mr-2"><strong>First Date:</strong></label>
                        <dp-date-picker theme="dp-material" formControlName="date" mode='day' [config]='config' #firstdp></dp-date-picker>
                    </div>
                    <div formGroupName="date1" class="form-inline alert alert-secondary">
                        <label for="datelabel" class="mr-2"><strong>Second Date Label:</strong></label>
                        <input type="text" formControlName="label" id="datelabel" class="mr-2">
                        <label class="mr-2"><strong>Second Date:</strong></label>
                        <dp-date-picker theme="dp-material" formControlName="date" mode='day' [config]='config' #seconddp></dp-date-picker>
                    </div>
                    <label class="mt-2"><strong>Tags/Keywords:&nbsp;</strong></label>
                    <input type="text" formControlName="tags" class="form-control">
                    <label class="mt-2"><strong>Images:&nbsp;</strong></label>
                    <div formGroupName="images0" class="alert alert-secondary">
                        <label class="mt-2"><strong>URL:&nbsp;</strong></label><span *ngIf="imagesurl.invalid" class="badge badge-pill badge-warning">Required</span>
                        <input type="text" formControlName="url" class="form-control">
                        <label class="mt-2"><strong>Name:&nbsp;</strong></label><span *ngIf="imagesname.invalid" class="badge badge-pill badge-warning">Required</span>
                        <input type="text" formControlName="name" class="form-control">
                        <label class="mt-2"><strong>Description:</strong></label>
                        <input type="text" formControlName="description" class="form-control">
                    </div>
                    <div id="image1fields">
                        <div formGroupName="images1" class="alert alert-secondary">
                            <label class="mt-2"><strong>URL:</strong></label>
                            <input type="text" formControlName="url" class="form-control">
                            <label class="mt-2"><strong>Name:</strong></label>
                            <input type="text" formControlName="name" class="form-control">
                            <label class="mt-2"><strong>Description:</strong></label>
                            <input type="text" formControlName="description" class="form-control">
                        </div>
                    </div>
                    <div id="image2fields">
                        <div formGroupName="images2" class="alert alert-secondary">
                            <label class="mt-2"><strong>URL:</strong></label>
                            <input type="text" formControlName="url" class="form-control">
                            <label class="mt-2"><strong>Name:</strong></label>
                            <input type="text" formControlName="name" class="form-control">
                            <label class="mt-2"><strong>Description:</strong></label>
                            <input type="text" formControlName="description" class="form-control">
                        </div>
                    </div>
                    <div id="image3fields">
                        <div formGroupName="images3" class="alert alert-secondary">
                            <label class="mt-2"><strong>URL:</strong></label>
                            <input type="text" formControlName="url" class="form-control">
                            <label class="mt-2"><strong>Name:</strong></label>
                            <input type="text" formControlName="name" class="form-control">
                            <label class="mt-2"><strong>Description:</strong></label>
                            <input type="text" formControlName="description" class="form-control">
                        </div>
                    </div>
                    <label class="mt-2"><strong>Video:</strong></label>
                    <div formGroupName="videos" class="alert alert-secondary">
                        <label class="mt-2"><strong>URL:</strong></label>
                        <input type="text" formControlName="url" class="form-control">
                        <label class="mt-2"><strong>Name:</strong></label>
                        <input type="text" formControlName="name" class="form-control">
                        <label class="mt-2"><strong>Description:</strong></label>
                        <input type="text" formControlName="description" class="form-control">
                    </div>
                    <label class="mt-2"><strong>Website:</strong></label>
                    <div formGroupName="websiteURLs" class="alert alert-secondary">
                        <label class="mt-2"><strong>URL:</strong></label>
                        <input type="text" formControlName="url" class="form-control">
                        <label class="mt-2"><strong>Name:</strong></label>
                        <input type="text" formControlName="name" class="form-control">
                    </div>
                    <label class="mt-2"><strong>Asset:</strong></label>
                    <div formGroupName="assets" class="alert alert-secondary">
                        <label class="mt-2"><strong>Name:</strong></label>
                        <input type="text" formControlName="name" class="form-control">
                        <label class="mt-2"><strong>URL:</strong></label>
                        <input type="text" formControlName="url" class="form-control">
                    </div>
                    <label class="mt-2"><strong>Related Artifacts:</strong></label>
                    <input type="text" formControlName="artifacts" class="form-control">
                    <label class="mt-2"><strong>Related Persons:</strong></label>
                    <input type="text" formControlName="persons" class="form-control">
                    <label class="mt-2"><strong>Related Events:</strong></label>
                    <input type="text" formControlName="events" class="form-control">
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" [disabled]="createdArchiveItem.invalid" (click)="saveNewArchiveItem()" data-dismiss="modal" >Save changes</button>
                <span *ngIf="createdArchiveItem.invalid" class="badge badge-pill badge-danger px-3 py-1">Data<br>incomplete</span>
            </div>
        </div>
    </div>
</div>
