    <!--+-+-+ +-+-+-+-+-+-+-+-+ +-+-+-+-+-+-+-+-+-+
    |A|D|A|M| |F|R|O|N|T|E|N|D| |S|T|R|U|C|T|U|R|E|
    +-+-+-+-+ +-+-+-+-+-+-+-+-+ +-+-+-+-+-+-+-+-+-+


   ┌──────────┐ PAGE STRUCTURE
   │   Home   │ 1. navbar.component with search and login (uses auth.service for
 ┌─┤   Page   │ login, sends search queries to search page).
 │ │          │ 2. carousel.component showing featured items (uses
 │ └──────────┘ apirequests.service to GET featured items).
 │              3. Static information on purpose of archive.
 │              4. archivecategories.component with search and links to the three
 │              categories (uses apirequests.service to GET category infos).
 │              5. Static information on how to contribute.
 │              6. newslist.component showing current news stories (uses
 │              apirequests.service to GET news items).
 │              7. footer.component.
 │              8. cookiewarning.component.
 │
 ├───────┬────────────────────────────────────────────────┐
 │       │                                                │
 │       ▼                                                ▼
 │ ┌──────────┐ PAGE STRUCTURE                       ┌──────────┐
 │ │ Archive  │ 1. navbar.component with search      │  Search  │
 │ │   Page   │ and login (uses auth.service         │ Results  │
 │ │          │ for login, sends search queries      │   Page   │
 │ └─────┬────┘ to search page).                     └────┬─────┘
 │       │      2. archivecategories.component            │
 │       │      (uses apirequests.service to              │ PAGE STRUCTURE
 │       │      GET category infos)                       │ 1. navbar.component with
 │       │      3. footer.component.                      │ search and login (uses
 │       │      4. cookiewarning.component.               │ auth.service for login,
 │       ▼                                                │ sends search queries to
 │      ┌──────────┐ PAGE STRUCTURE                       │ search page).
 │      │ Category │ 1. navbar.component with search      │ 2. searchall.component with
 │      │   List   │ and login (uses auth.service for     │ a searchfilter and
 │      │   page   │ login, sends search queries to       │ pagination for the results
 │      └─────┬────┘ search page).                        │ (uses apirequests.component
 │            │      2. categorylist.component (uses      │ to GET category items).
 │            │      apirequests.service to GET this      │ 3. footer.component.
 │            │      category's infos).                   │ 4. cookiewarning.component.
 │            │      3. footer.component.                 │
 │            │      4. cookiewarning.component.          │
 │            │    ┌──────────────────────────────────────┘
 │            │    │
 │            ▼    ▼
 │           ┌──────────┐ PAGE STRUCTURE
 │           │   Item   │ 1. navbar.component with search and login
 │           │ Details  │ (uses auth.service for login, sends
 │           │   Page   │ search queries to search page).
 │           └──────────┘ 2. itemdetails.component (uses
 │                        apirequests.service to GET item details
 │                        and auth.service to allow
 │                        editing/deleting the item).
 │                        3. footer.component.
 │                        4. cookiewarning.component.
 │
 ├───────┬────────────────────────────────────────────────┐
 │       │                                                │
 │       ▼                                                ▼
 │ ┌──────────┐ PAGE STRUCTURE                       ┌──────────┐ PAGE STRUCTURE
 │ │   Edit   │ 1. navbar.component with search and  │   User   │ 1. navbar.component with search
 │ │  Items   │ login (uses auth.service for login,  │ Profile  │ and login (uses auth.service
 │ │   Page   │ sends search queries to search page).│   Page   │ for login, sends search queries
 │ └──────────┘ 2. createarchiveitem.component (uses └──────────┘ to search page).
 │              apirequests.service to PUT new                    2. authprofile.component (uses
 │              items).                                           auth.service for user profile
 │              3. editcarouselfeatures.component                 data).
 │              (uses apirequests.service to GET and              3. footer.component.
 │              PUT featured items).                              4. cookiewarning.component.
 │              4. editnewspages.component (uses
 │              apirequests.service to GET and PUT
 │              news).
 │              5. editcompliancepages.component
 │              (uses apirequests.service to GET and
 │              PUT compliance pages).
 │              6. footer.component.
 │              7. cookiewarning.component.
 │
 └───────┬────────────────────────────────────────────────┐
         │                                                │
         ▼                                                ▼
   ┌──────────┐ PAGE STRUCTURE                       ┌──────────┐ PAGE STRUCTURE
   │News List │ 1. navbar.component with search      │Compliance│ 1. navbar.component with search and
   │   Page   │ and login (uses auth.service         │   Page   │ login (uses auth.service for login,
   │          │ for login, sends search queries      │          │ sends search queries to search page).
   └─────┬────┘ to search page).                     └────┬─────┘ 2. compliancearticle.component to
         │      2. newslist.component (uses               │       show the text of the current
         │      apirequests.service to GET news           │       compliance page based on URL slug
         │      items).                                   │       (uses apirequests.service to GET the
         │      3. footer.component.                      │       compliance text).
         │                                                │       3. footer.component.
         │                                                │       4. cookiewarning.component.
         ▼                                                ▼
        ┌──────────┐ PAGE STRUCTURE                      ┌──────────┐ PAGE STRUCTURE
        │   News   │ 1. navbar.component with search     │ Contact  │ 1. navbar.component with search
        │ Article  │ and login (uses auth.service        │   Page   │ and login (uses auth.service
        │   Page   │ for login, sends search queries     │          │ for login, sends search queries
        └──────────┘ to search page).                    └──────────┘ to search page).
                     2. newsarticle.component to                      2. Static information with
                     show the text of the current                     infos and contact form.
                     news item based on URL slug                      3. footer.component.
                     (uses apirequests.service to                     4. cookiewarning.component.
                     GET the news item).
                     3. footer.component.
                     4. cookiewarning.component.
-->

<router-outlet></router-outlet>
