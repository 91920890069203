<div class="container-fluid">
	<div class="row">
		<div class="col-12">
        <nav aria-label="breadcrumb" class="mt-2">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/archive">Archive</a></li>
                <li class="breadcrumb-item active" aria-current="page">Archive category: {{ catSlug }}</li>
            </ol>
        </nav>
			<div class="dropdown ml-2 mb-2">
				<button class="btn btn-outline-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					Archive Category:  {{ catSlug }}
				</button>
				<div class="dropdown-menu" aria-labelledby="dropdownMenuButton" >
					<a href="#" class="dropdown-item" routerLink="/category/artifacts" routerLinkActive="active">Artifacts</a>
					<a href="#" class="dropdown-item" routerLink="/category/events" routerLinkActive="active">Events</a>
					<a href="#" class="dropdown-item" routerLink="/category/persons" routerLinkActive="active">Persons</a>
				</div>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-10 offset-1 text-center">
			<h2>Archive Category: {{ catSlug }}</h2>
			<h3>At present, all items in this category only contain placeholder text.</h3>
			<h4 class="mt-3">Search {{ catSlug }}</h4>
		</div>
	</div>
	<div class="row">
		<div class="col-6 offset-3 text-center mb-4">
			<div class="input-group mb-3">
				<div class="input-group-prepend">
					<span class="input-group-text" id="basic-addon1">Search</span>
				</div>
				<input placeholder="Search this category" [(ngModel)]="searchText" name="search" type="text" class="p-2 text-center form-control">
			</div>
		</div>
	</div>
	
	<div class="container">


		<div class="row">
			<div class="col-12 text-center">
				<h4 *ngIf="searchText">Search results for "{{ searchText }}" in {{ catSlug }}:</h4>
				<pagination-template #p="paginationApi" [id]="config.id" (pageChange)="config.currentPage = $event">
	
					<nav aria-label="Search results">
						<div class="btn-group mb-3" role="group" aria-label="Search result pages">
							
							<button type="button" class="btn btn-light" *ngIf="!p.isFirstPage()" (click)="p.previous()" aria-label="Previous">
								<span> &laquo; Previous</span>
							</button>
	
							<div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
								<button type="button" class="btn btn-light" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
									<span>{{ page.label }}</span>
								</button>
								<button type="button" class="btn btn-secondary" aria-current="page" *ngIf="p.getCurrent() === page.value" >
									<span>{{ page.label }}</span>
								</button>
							</div>
	
							<button type="button" class="btn btn-light" *ngIf="!p.isLastPage()" (click)="p.next()" aria-label="Next">
								<span>Next &raquo;</span>
							</button>
	
						</div>
					</nav>
	
				</pagination-template>
	
				<div class="row mb-5 ">
					<div class="col-lg-3 col-md-3 col-sm-6 text-center shadow-sm" *ngFor="let item of archiveCategory | filter:searchText | paginate: config">
						<a routerLink="/item/{{ item.category }}/{{ item._id }}"><img src="{{ item.images[0].url }}" alt="{{ item.name }}" class="img-fluid rounded"></a>
						<h4 class="mt-2"><a routerLink="/item/{{ item.category }}/{{ item._id }}">{{ item.name }}</a></h4>
						<p>{{ item.shortdescription }}</p>
						<p><a routerLink="/item/{{ item.category }}/{{ item._id }}" class="btn btn-outline-secondary ml-1 mt-2">Details</a></p>
					</div>
				</div>
	
			</div>
		</div>

	</div>
</div>