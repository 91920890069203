<div class="toppadding container-fluid">

    <div class="row">
        <div class="col-lg-12">            
            <!-- Breadcrumbs -->
            <nav aria-label="breadcrumb" class="mt-2">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a  routerLink="/">Home</a></li>
                    <li class="breadcrumb-item"><a  routerLink="/archive">Archive</a></li>
                    <li class="breadcrumb-item"><a  routerLink="/category/{{ archiveCategory }}">Archive category: {{ archiveCategory | titlecase }}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{ archiveItem.name }}</li>
                </ol>
            </nav>

            <div *ngIf="auth.loggedIn" >
                <button class="btn btn-outline-danger btn-sm ml-2 mb-2" data-toggle="modal" data-target="#editItem">Edit item</button>
                <button class="btn btn-danger btn-sm ml-2 mb-2" data-toggle="modal" data-target="#deleteItemModal">Delete item</button>

                <!-- Modal to confirm deleting the item -->
                <div class="modal fade" id="deleteItemModal" tabindex="-1" aria-labelledby="deleteItemModal" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="deleteItemModalLabel">Delete Item</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <h3>Are you sure you want to delete this item?</h3>
                                <p class="alert alert-danger text-center"><a href="https://adam.demoarchive.art/item/{{ pageUrl }}">https://adam.demoarchive.art/item/{{ pageUrl }}</a></p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                <button type="button" class="btn btn-danger" (click)="deleteThis(archiveCategory, archiveItem.id)" data-dismiss="modal" routerLink="/archive">Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>

<!-- Modal for shareable link -->
<div class="modal fade" id="shareLink" tabindex="-1" aria-labelledby="shareLink" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="shareLinkLabel">Share Link</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>Share this direct link to this item with others:</p>
            <input class="form-control text-center" #itemurl value="https://adam.demoarchive.art/item/{{ pageUrl }}">
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" (click)="clipboardItemURL(itemurl)" data-dismiss="modal">Copy to Clipboard</button>
        </div>
        </div>
    </div>
</div>

<!-- Error message for non-existent IDs -->
<div *ngIf="!archiveItem.name" class="container">
    <div *ngIf="!timeElapsed" class="row my-5">
        <div class="col-12 py-5 my-5">
            <div id="loader">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="loading"></div>
            </div>
        </div>
    </div>
    <div *ngIf="timeElapsed" class="row my-4">
        <div class="col-lg-12 alert alert-danger text-center p-5" role="alert">
            <h1>This archive item does not exist.</h1>
            <p>Please return to the <strong>archive category</strong> or the <strong>main archive page.</strong></p>
            <p>
                <a class="btn btn-light" routerLink="/category/{{ archiveCategory }}">Archive category: {{ archiveCategory | titlecase }}</a>&nbsp;<a class="btn btn-light" routerLink="/archive">Main Archive</a>
            </p>
        </div>
    </div>
</div>

<!-- Main Item Details -->
<div *ngIf="archiveItem.name" class="container">
    <div class="row my-4">
        <div class="col-lg-8 border-bottom">
        <h2>{{ archiveItem.name }}</h2>

        <h3>Characteristics</h3>
        <table class="table table-sm table-hover">
            <tbody>
            <tr>
                <th scope="row">Name:</th>
                <td>{{ archiveItem.name }}</td>
            </tr>
            <tr>
                <th scope="row">Category:</th>
                <td>{{ archiveCategory | titlecase }}</td>
            </tr>
            <tr>
                <th scope="row">Aliases:</th>
                <td *ngIf="archiveItem.aliases[0]?.length">{{ archiveItem.aliases }}</td>
                <td *ngIf="!archiveItem.aliases[0]?.length">None</td>
            </tr>
            <tr>
                <th scope="row">Dates:</th>
                <td *ngIf="archiveItem.dates[0].label?.length"><span class="mr-2" *ngFor="let dateitem of archiveItem.dates"><span class="mr-1">{{ dateitem.label }}</span>{{ dateitem.date | date }}</span></td>
                <td *ngIf="!archiveItem.dates[0].label?.length">None</td>
            </tr>
            <tr>
                <th scope="row">Summary:</th>
                <td>{{ archiveItem.shortdescription }}</td>
            </tr>
            <tr>
                <th scope="row">Share:</th>
                <td>
                <a class="btn btn-outline-primary btn-sm mr-1" data-toggle="modal" data-target="#shareLink" href="#">Page Link</a>
                <a class="btn btn-outline-primary btn-sm mr-1" href="https://www.facebook.com/sharer/sharer.php?u=https://adam.demoarchive.art/item/{{ pageUrl }}" target="_blank">Share on Facebook</a> 
                <a class="btn btn-outline-primary btn-sm mr-1" href="https://twitter.com/intent/tweet?url=https://adam.demoarchive.art/item/{{ pageUrl }}" target="_blank">Share on Twitter</a></td>
            </tr>
            </tbody>
        </table>

        <h3>Description</h3>
        <div [innerHTML]="archiveItem.longdescription"></div>

            <div class="row mt-4">
                <div class="col-12">
                    <h4>Keywords</h4>
                    <p *ngIf="archiveItem.tags[0]?.length" class="mt-4">
                        <a class="btn btn-warning m-1" *ngFor="let tag of archiveItem.tags" routerLink="/search/{{tag}}">{{ tag }}</a>
                    </p>
                    <p *ngIf="!archiveItem.tags[0]?.length" class="mt-4">
                        No keywords or tags available.
                    </p>
                </div>
            </div>

        </div>
        <div class="col-lg-4 ">
        <div class="row">
            <div class="col-12 text-center">
            <h3>Images</h3>
            </div>
        </div>
        <div *ngIf="!archiveItem.images[0].url?.length" class="row pt-3 border">
            <div class="col-12">
                <p class="text-center">
                    No related images available.
                </p>
            </div>
        </div>
        <div *ngIf="archiveItem.images[0].url?.length" class="row pt-3 border">
            <div class="col-6 text-center" *ngFor="let item of archiveItem.images" (click)="setCarouselActiveID(item.url)">
            <a href="#" data-toggle="modal" data-target="#carouselModal" >
                <img src="{{ item.url }}" class="img-fluid rounded-lg" alt="{{ item.name }}">
                <p>{{ item.name }}</p>
            </a>
            </div>
        </div>

        <!-- Modal for Image Carousel -->
        <!-- Images and text information are passed onto the carousel in the modal. The URL is used as an ID. -->
        <div class="modal fade" id="carouselModal" tabindex="-1" aria-labelledby="carouselModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                <h5 class="modal-title" id="carouselModalLabel">Images for "{{ archiveItem.name }}"</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">

                <div id="carouselCaptions" class="carousel slide" data-ride="carousel" data-interval="false" touch="true">
                    <ol class="carousel-indicators">
                    <li data-target="#carouselCaptions" *ngFor="let item of archiveItem.images; let isFirst = first" [class.active]="isFirst"></li>
                    </ol>
                    <div class="carousel-inner">
                    <div class="carousel-item" *ngFor="let item of archiveItem.images; let isFirst = first" [class.active]="isFirst" id="{{ item.url }}">
                        <img src="{{ item.url }}" class="d-block w-100" alt="{{ item.name }}" >
                    </div>
                    </div>
                    <a class="carousel-control-prev" href="#carouselCaptions" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselCaptions" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                    </a>
                </div>

                </div>
                <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-center mt-3">
            <h3>Videos</h3>
            </div>
        </div>
        <div class="row border">
            <div *ngIf="!archiveItem.videos[0].url?.length" class="col-12 p-3">
                <p class="text-center">No related videos available.</p>
            </div>
            <div *ngIf="archiveItem.videos[0].url?.length" class="col-12 p-3">
                <p *ngFor="let item of archiveItem.videos" class="text-center alert alert-primary">
                    <a href="{{ item.url }}" target="_blank">{{ item.name }}</a>
                </p>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-center mt-3">
            <h3>Websites</h3>
            </div>
        </div>
        <div class="row border">
            <div *ngIf="!archiveItem.websiteURLs[0].url?.length" class="col-12 p-3">
                <p class="text-center">No related websites available.</p>
            </div>
            <div  *ngIf="archiveItem.websiteURLs[0].url?.length" class="col-12 p-3">
                <p *ngFor="let item of archiveItem.websiteURLs" class="text-center alert alert-success">
                    <a href="{{ item.url }}" target="_blank">{{ item.name }}</a>
                </p>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-center mt-3">
            <h3>Assets</h3>
            </div>
        </div>
        <div class="row border">
            <div *ngIf="!archiveItem.assets[0].url?.length" class="col-12 p-3">
                <p class="text-center">No related assets available.</p>
            </div>
            <div *ngIf="archiveItem.assets[0].url?.length" class="col-12 p-3">
                <p *ngFor="let item of archiveItem.assets" class="text-center alert alert-secondary">
                    <a href="{{ item.url }}" target="_blank">{{ item.name }}</a>
                </p>
            </div>
        </div>

        </div>
    </div>

<div class="row">
    <div class="col-12">
    <h3>Related Archive Entries</h3>
    </div>
</div>

<div class="row">
    <!-- Related persons -->
    <div class="col-lg-2 col-md-3 text-center my-1" *ngFor="let item of relatedPersons">
        <a routerLink="/item/persons/{{ item._id }}"><img src="{{ item.images[0].url }}" alt="{{ item.name }}" class="img-fluid rounded"></a>
        <span class="badge badge-secondary">Person</span>
        <p><a routerLink="/item/persons/{{ item._id }}">{{ item.name }}</a></p>
    </div>

    <!-- Related artifacts -->
    <div class="col-lg-2 col-md-3 text-center my-1" *ngFor="let item of relatedArtifacts">
        <a routerLink="/item/artifacts/{{ item._id }}"><img src="{{ item.images[0].url }}" alt="{{ item.name }}" class="img-fluid rounded"></a>
        <span class="badge badge-secondary">Artifact</span>
        <p><a routerLink="/item/artifacts/{{ item._id }}">{{ item.name }}</a></p>
    </div>

    <!-- Related events -->
    <div class="col-lg-2 col-md-3 text-center my-1" *ngFor="let item of relatedEvents">
        <a routerLink="/item/events/{{ item._id }}"><img src="{{ item.images[0].url }}" alt="{{ item.name }}" class="img-fluid rounded"></a>
        <span class="badge badge-secondary">Event</span>
        <p><a routerLink="/item/events/{{ item._id }}">{{ item.name }}</a></p>
    </div>
</div>

<!-- Modal for editing items -->
<!-- Users enter the information, it is then passed to the API request service and sent as POST -->
<div class="modal fade" id="editItem" tabindex="-1" aria-labelledby="editItem" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="editItemLabel">Edit "{{ archiveItem.name }}"</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div [formGroup]="editingFormArchiveItem" class="form-group">
                <div class="mt-2 input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text"><strong>Item Id:</strong></div>
                    </div>
                    <input type="text" formControlName="id" class="form-control" readonly>
                </div>
                <div class="mt-2 input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text"><strong>Category:</strong></div>
                    </div>
                    <input type="text" formControlName="category" class="form-control" readonly>
                </div>

                <label class="mt-2"><strong>Name:</strong></label>
                <input type="text" formControlName="name" class="form-control">
                <label class="mt-2"><strong>Aliases:</strong></label>
                <input type="text" formControlName="aliases" class="form-control">
                <label class="mt-2"><strong>Short Description:</strong></label>
                <input type="text" formControlName="shortdescription" class="form-control">
                <label class="mt-2"><strong>Long Description:</strong></label>
                <!-- The TinyMCE text editor is inserted here -->
                <editor formControlName="longdescription"
                    apiKey="kzw0zdrheylzt0ms8wq28xfgawoi9ltzt8n4t76a42uphen2"
                    [init]="{
                        height: 500,
                        menubar: false,
                        plugins: [
                        'advlist autolink lists link image charmap print',
                        'preview anchor searchreplace visualblocks code',
                        'fullscreen insertdatetime media table paste',
                        'help wordcount'
                        ],
                        toolbar:
                        'undo redo | formatselect | bold italic | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | help'
                    }"
                    >
                </editor>
                <label class="mt-2"><strong>Dates:</strong></label>
                <div formGroupName="date0" class="form-inline alert alert-secondary">
                    <label for="datelabel0" class="mr-2"><strong>First Date Label:</strong></label>
                    <input type="text" formControlName="label" id="datelabel0" class="mr-2">
                    <label class="mr-2"><strong>First Date:</strong></label>
                    <dp-date-picker theme="dp-material" formControlName="date" mode='day' [config]='config' #firstdp></dp-date-picker>
                </div>
                <div formGroupName="date1" class="form-inline alert alert-secondary">
                    <label for="datelabel1" class="mr-2"><strong>First Date Label:</strong></label>
                    <input type="text" formControlName="label" id="datelabel1" class="mr-2">
                    <label class="mr-2"><strong>First Date:</strong></label>
                    <dp-date-picker theme="dp-material" formControlName="date" mode='day' [config]='config' #seconddp></dp-date-picker>
                </div>
                <label class="mt-2"><strong>Tags/Keywords:</strong></label>
                <input type="text" formControlName="tags" class="form-control">
                <label class="mt-2"><strong>Images:</strong></label>
                <div formGroupName="image0" class="alert alert-secondary">
                    <div class="input-group">
                        <label class="mt-2 mr-2"><strong>URL:</strong></label>
                        <input type="text" formControlName="url" class="form-control" id="imagepath0">
                        <div class="input-group-append">
                            <button class="btn btn-warning btn-sm" type="button"  data-toggle="modal" data-target="#uploader"  (click)="getImagePath(0)">Upload image</button>
                         </div>
                    </div>
                    <label class="mt-2"><strong>Name:</strong></label>
                    <input type="text" formControlName="name" class="form-control">
                    <label class="mt-2"><strong>Description:</strong></label>
                    <input type="text" formControlName="description" class="form-control">
                </div>
                <div formGroupName="image1" class="alert alert-secondary">
                    <div class="input-group">
                        <label class="mt-2 mr-2"><strong>URL:</strong></label>
                        <input type="text" formControlName="url" class="form-control">
                        <div class="input-group-append">
                            <button class="btn btn-warning btn-sm" type="button"  data-toggle="modal" data-target="#uploader" (click)="getImagePath(1)">Upload image</button>
                        </div>
                    </div>
                    <label class="mt-2"><strong>Name:</strong></label>
                    <input type="text" formControlName="name" class="form-control">
                    <label class="mt-2"><strong>Description:</strong></label>
                    <input type="text" formControlName="description" class="form-control">
                </div>
                <div formGroupName="image2" class="alert alert-secondary">
                    <div class="input-group">
                        <label class="mt-2 mr-2"><strong>URL:</strong></label>
                        <input type="text" formControlName="url" class="form-control">
                        <div class="input-group-append">
                            <button class="btn btn-warning btn-sm" type="button"  data-toggle="modal" data-target="#uploader" (click)="getImagePath(2)">Upload image</button>
                        </div>
                    </div>
                    <label class="mt-2"><strong>Name:</strong></label>
                    <input type="text" formControlName="name" class="form-control">
                    <label class="mt-2"><strong>Description:</strong></label>
                    <input type="text" formControlName="description" class="form-control">
                </div>
                <div formGroupName="image3" class="alert alert-secondary">
                    <div class="input-group">
                        <label class="mt-2 mr-2"><strong>URL:</strong></label>
                        <input type="text" formControlName="url" class="form-control">
                        <div class="input-group-append">
                            <button class="btn btn-warning btn-sm" style="border: none" type="button"  data-toggle="modal" data-target="#uploader" (click)="getImagePath(3)">Upload image</button>
                        </div>
                    </div>
                    <label class="mt-2"><strong>Name:</strong></label>
                    <input type="text" formControlName="name" class="form-control">
                    <label class="mt-2"><strong>Description:</strong></label>
                    <input type="text" formControlName="description" class="form-control">
                </div>
                <label class="mt-2"><strong>Videos:</strong></label>
                <div formGroupName="videos" class="alert alert-secondary">
                    <label class="mt-2"><strong>URL:</strong></label>
                    <input type="text" formControlName="url" class="form-control">
                    <label class="mt-2"><strong>Name:</strong></label>
                    <input type="text" formControlName="name" class="form-control">
                    <label class="mt-2"><strong>Description:</strong></label>
                    <input type="text" formControlName="description" class="form-control">
                </div>
                <label class="mt-2"><strong>Websites:</strong></label>
                <div formGroupName="websiteURLs" class="alert alert-secondary">
                    <label class="mt-2"><strong>URL:</strong></label>
                    <input type="text" formControlName="url" class="form-control">
                    <label class="mt-2"><strong>Name:</strong></label>
                    <input type="text" formControlName="name" class="form-control">
                </div>
                <label class="mt-2"><strong>Assets:</strong></label>
                <div formGroupName="assets" class="alert alert-secondary">
                    <label class="mt-2"><strong>Name:</strong></label>
                    <input type="text" formControlName="name" class="form-control">
                    <label class="mt-2"><strong>URL:</strong></label>
                    <input type="text" formControlName="url" class="form-control">
                </div>
                <label class="mt-2"><strong>Related Artifacts:</strong></label>
                <input type="text" formControlName="artifacts" class="form-control">
                <label class="mt-2"><strong>Related Persons:</strong></label>
                <input type="text" formControlName="persons" class="form-control">
                <label class="mt-2"><strong>Related Events:</strong></label>
                <input type="text" formControlName="events" class="form-control">
            </div>

        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary"  [disabled]="editingFormArchiveItem.invalid" (click)="saveEditedArchiveItem()" data-dismiss="modal" routerLink="/item/{{ archiveCategory }}/{{ archiveItem._id }}#{{ randomAddress }}">Save changes</button>
            <span *ngIf="editingFormArchiveItem.invalid" class="badge badge-pill badge-danger px-3 py-1">Data<br>incomplete</span>
        </div>
        </div>
    </div>
</div>

<!-- Modal for image uploads-->
<div class="modal fade" id="uploader" tabindex="-1" role="dialog" aria-labelledby="uploadImageModal" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="uploadImageModal">Image Upload</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>
                    <label class="mylabel" for="upload">Upload image file anonymously to <a href="https://imgur.com" target="_blank">Imgur</a>:</label><br />
                    <input type="file" id="upload" (change)="handleImage($event)" />
                </p>
                <button type="button" class="btn btn-warning uploadbutton mb-4" (click)="uploadImage()">Upload image</button>
                <div class="row">
                    <div *ngIf="previousimagepath" class="col-sm-6">
                        <div class=" alert alert-secondary p-2 mt-1 mr-1">
                            <p><strong>Current</strong> image:</p>
                            <p>
                                <img src="{{ previousimagepath }}" class="img-fluid">
                            </p>
                        </div>
                    </div>
                    <div *ngIf="imageData" class="col-sm-6">
                        <div class=" alert alert-info p-2 mt-1 ml-1">
                            <p><strong>New</strong> upload:</p>
                            <p>
                                <img src="{{ imageData }}" class="img-fluid">
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button  *ngIf="imageData" type="button" class="btn btn-primary" (click)="storeImagePath()" data-dismiss="modal">Save Path</button>
                <span *ngIf="!imageData" class="badge badge-pill badge-danger px-3 py-1">No new<br>upload</span>
            </div>
        </div>
    </div>
</div>
