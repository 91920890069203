<!-- This view enables users who are logged in as editors to CREATE ARCHIVE ITEMS and EDIT FEATURED ITEMS, COMPLIANCE NEWS PAGES.
     It is only accessible if a user is logged in (verified through Angular routing).

     PAGE STRUCTURE
     ┌──────────┐ 
     │   Edit   │ 1. navbar.component with search and
     │  Items   │    login (uses auth.service for login,
     │   Page   │    sends search queries to search page).
     └──────────┘ 2. createarchiveitem.component (uses
                     apirequests.service to PUT new items).
                  3. editcarouselfeatures.component
                     (uses apirequests.service to GET and
                     PUT featured items).
                  4. editnewspages.component (uses
                     apirequests.service to GET and PUT news).
                  5. editcompliancepages.component
                     (uses apirequests.service to GET and
                     PUT compliance pages).
                  6. footer.component.
                  7. cookiewarning.component. -->


<app-navbar></app-navbar>
<div class="container toppadding mb-4">
    <div class="row">
        <div class="col-md-8">
            <h2>Welcome to the Editor's Area of ADAM</h2>
            <p>Here you can add new and edit existing artifacts, persons and events, as well as edit <strong>featured items,</strong>&nbsp;<strong>news</strong> and <strong>compliance pages</strong>.</p>
            <p>To edit existing archive items, please visit the <a routerLink="/archive"><strong>archive</strong></a> and <strong>edit</strong> or <strong>delete</strong> individual items.</p>
            <div class="row">
                <div class="col-md-6 mt-2">
                    <div class="card p-3">
                        
                        <!-- This component provides the information, the button trigger and the modal to create new archive items -->
                        <app-createarchiveitem></app-createarchiveitem>

                    </div>
                </div>
                <div class="col-md-6 mt-2">
                    <div class="card p-3">

                        <!-- This component provides the information, the button trigger and the modal to edit the featured items in the carousel on home -->
                        <app-editcarouselfeatures></app-editcarouselfeatures>

                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 mt-2">
                    <div class="card p-3">

                        <!-- This component provides the information, the button trigger and the modal to edit the contents of the compliance pages -->
                        <app-editcompliancepages></app-editcompliancepages>

                    </div>
                </div>
                <div class="col-md-6 mt-2">
                    <div class="card p-3">

                        <!-- This component provides the information, the button trigger and the modal to edit the news articles -->
                        <app-editnewspages></app-editnewspages>

                    </div>
                </div>
            </div>

        </div>
        <div class="col-md-4 text-center">
            <h3>How to Edit and Create Content on ADAM</h3>
            <img src="assets/img/instructional-video.png" alt="The instructional video is coming soon." class="img-fluid">
            <p>Learn how to add new and edit existing <strong>artifacts,</strong>&nbsp;<strong>persons</strong>&nbsp;and&nbsp;<strong>events,</strong>&nbsp;as well as edit <strong>featured items,</strong>&nbsp;<strong>news</strong>&nbsp;and&nbsp;<strong>compliance pages.</strong></p>
        </div>
    </div>
</div>

<app-footer></app-footer>
<app-cookiewarning></app-cookiewarning>
