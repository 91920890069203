<div class="row">
    <div class="col-12">
        <nav aria-label="breadcrumb" class="mt-2">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a  routerLink="/">Home</a></li>
                <li class="breadcrumb-item"><a  routerLink="/news">News</a></li>
                <li class="breadcrumb-item active" aria-current="page"><span class="blogPostBreadcrumb">{{ newsitem.title }}</span></li>
            </ol>
        </nav>
    </div>
</div>‭
<div class="row">
    <div class="col-lg-7 col-sm-12 offset-md-1">
        <div *ngIf="newsitem.title" class="mt-2 mb-5">
            <h2 class="mr-4 my-4">{{ newsitem.title }}</h2>
            <h3 class="mr-4 mb-4">{{ newsitem.shortdescription }}</h3>
            <div class="mr-4 mb-4"><img src="{{ newsitem.largeimage }}" class="img-fluid"></div>
            <div class="mr-4 mb-5">
                <div [innerHTML]="newsitem.articletext"></div>
            </div>
        </div>
        <div *ngIf="!newsitem.title" class="alert alert-danger text-center p-4" role="alert">
            <h3>There is no news article on this page.</h3>
        </div>        
    </div>
    <div class="col-lg-4 col-sm-12">
        <div *ngIf="newsitem.title">
            <img src="{{ newsitem.image}}" alt="{{ newsitem.title }}" class="mt-2 mb-4 rounded img-fluid">
        </div>
        <h4 class="my-4">All Posts</h4>
        <ul>
            <li *ngFor="let item of newsitems" class="my-2"><a [routerLink]="['/newsarticle/', (item.uniquename)]" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">{{ item.title }}</a></li>
        </ul>
    </div>
</div>