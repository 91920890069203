
<h3>Edit the Compliance Pages</h3>
<img src="assets/img/edititems-edit-compliance.jpg" data-toggle="modal" data-target="#editCompliance" alt="Edit the Compliance Pages" class="img-fluid border border-primary">
<p>Edit the Compliance Pages (except for the contracts page).</p>
<p><button class="btn btn-outline-secondary" data-toggle="modal" data-target="#editCompliance">Edit Compliance Pages</button></p>

<!-- Modal for editing Compliance Pages -->
<div class="modal fade" id="editCompliance" tabindex="-1" aria-labelledby="editCompliance" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="editComplianceLabel">Edit Compliance Pages</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div [formGroup]="editCompliancePages" class="form-group" *ngFor="let item of compliancePages">
                    <h4>Compliance Page {{item.title}} </h4>
                    <div formGroupName="compliance{{ item.category }}" class="form-group alert alert-secondary">
                        <div class="mt-2 input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><strong>Item Id:</strong></div>
                            </div>
                            <input type="text" formControlName="_id" class="form-control" readonly>
                        </div>
                        <label class="mt-2"><strong>Title:</strong></label>
                        <input type="text" formControlName="title" class="form-control">
                        <label class="mt-2"><strong>First Image:</strong></label>
                        <input type="text" formControlName="firstimage" class="form-control">
                        <label class="mt-2"><strong>Second Image:</strong></label>
                        <input type="text" formControlName="secondimage" class="form-control">
                        <label class="mt-2"><strong>Article Text "{{ item.id | titlecase }}":</strong></label>
                        <editor formControlName="articletext"
                            apiKey="kzw0zdrheylzt0ms8wq28xfgawoi9ltzt8n4t76a42uphen2"
                            [init]="{
                                height: 500,
                                menubar: false,
                                plugins: [
                                'advlist autolink lists link image charmap print',
                                'preview anchor searchreplace visualblocks code',
                                'fullscreen insertdatetime media table paste',
                                'help wordcount'
                                ],
                                toolbar:
                                'undo redo | formatselect | bold italic | \
                                alignleft aligncenter alignright alignjustify | \
                                bullist numlist outdent indent | help'
                            }"
                            >
                        </editor>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" [disabled]="editCompliancePages.invalid" (click)="saveCompliancePages()" data-dismiss="modal">Save changes</button>
                <span *ngIf="editCompliancePages.invalid" class="badge badge-pill badge-danger px-3 py-1">Data<br>incomplete</span>
            </div>
        </div>
    </div>
</div>