<h3>Edit the Featured Items</h3>
<img src="assets/img/edititems-edit-featured.jpg" data-toggle="modal" data-target="#editFeatured" alt="Edit the Featured Items" class="img-fluid border border-primary">
<p>Edit any of the four items featured in the carousel on the frontpage.</p>
<p><button class="btn btn-outline-secondary" data-toggle="modal" data-target="#editFeatured">Edit Featured Items</button></p>

<!--Modal for editing Featured Items -->
<div class="modal fade" id="editFeatured" tabindex="-1" aria-labelledby="editFeatured" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title" id="editFeaturedLabel">Edit Featured Items</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body">

                <h3>Featured Items</h3>
                <p>Four Items are featured in the carousel on the frontpage. They can be any of the existing archive item in the categories <strong>Artifacts,</strong> <strong>Persons,</strong> and <strong>Events.</strong> The carousel is limited to four items to stay concise.</p>
                <p>Featured items have their own <strong>title</strong> and <strong>description</strong> that are independent of the name and desription in the actual archive item's page. The idea is that you can write a promotional text for the featured item. If you want the same text, then simply copy the text from the archive item's page. Please note that the description should not exceed two sentences.</p>
                <p>The carousel provides a large canvas to create a great impression. Therefore the <strong>images</strong> in the carousel are much larger. Please provide the image in a resolution of 1200 x 600 pixels.</p>

                <div [formGroup]="featuredEdited" class="form-group" *ngFor="let item of featuredItemsArray, let i = index">
                    <h4>Featured Item {{ item.id }} </h4>
                    <div formGroupName="featured{{ i }}" class="form-group alert alert-secondary">
                        <div class="mt-2 input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><strong>Item Id:</strong></div>
                            </div>
                            <input type="text" formControlName="_id" class="form-control" readonly>
                        </div>
                        <label class="mt-2"><strong>Image:</strong></label>
                        <input type="text" formControlName="image" class="form-control">
                        <label class="mt-2"><strong>Title:</strong></label>
                        <input type="text" formControlName="title" class="form-control">
                        <label class="mt-2"><strong>Description:</strong></label>
                        <input type="text" formControlName="description" class="form-control">
                        <label class="mt-2"><strong>Link:</strong></label>
                        <input type="text" formControlName="link" class="form-control" >
                    </div>
                </div>

            </div>
            <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" [disabled]="featuredEdited.invalid" (click)="saveFeaturedItems()" data-dismiss="modal">Save changes</button>
            <span *ngIf="featuredEdited.invalid" class="badge badge-pill badge-danger px-3 py-1">Data<br>incomplete</span>
            </div>
        </div>
    </div>
</div>
