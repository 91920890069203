<div class="row">
    <div class="col-12 text-center my-5">
        <h2>Search</h2>
        <h3>Search the whole archive</h3>
        <div class="row">
            <div class="col-6 offset-3 text-center">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">Search</span>
                    </div>
                    <input type="text" [(ngModel)]="searchText" class="form-control text-center" placeholder="Search the whole archive" name="search" type="text">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12 text-center">
        <h4 *ngIf="searchText">Search results for "{{ searchText }}":</h4>
        <pagination-template #p="paginationApi" [id]="config.id" (pageChange)="config.currentPage = $event">

            <nav aria-label="Search results">
                <div class="btn-group mb-3" role="group" aria-label="Search result pages">
                    
                    <button type="button" class="btn btn-light" *ngIf="!p.isFirstPage()" (click)="p.previous()" aria-label="Previous">
                        <span> &laquo; Previous</span>
                    </button>

                    <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                        <button type="button" class="btn btn-light" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                            <span>{{ page.label }}</span>
                        </button>
                        <button type="button" class="btn btn-secondary" aria-current="page" *ngIf="p.getCurrent() === page.value" >
                            <span>{{ page.label }}</span>
                        </button>
                    </div>

                    <button type="button" class="btn btn-light" *ngIf="!p.isLastPage()" (click)="p.next()" aria-label="Next">
                        <span>Next &raquo;</span>
                    </button>

                </div>
            </nav>

        </pagination-template>

        <div class="row mb-5 ">
            <div class="col-lg-3 col-md-3 col-sm-6 text-center shadow-sm" *ngFor="let item of allData | filter:searchText | paginate: config">
                <a class="badge badge-primary mt-3" routerLink="/item/{{ item.category }}/{{ item._id }}">{{ item.category | titlecase }}</a>
                <a routerLink="/item/{{ item.category }}/{{ item._id }}"><img src="{{ item.images[0].url }}" alt="{{ item.name }}" class="img-fluid rounded"></a>
                <h4 class="mt-2"><a routerLink="/item/{{ item.category }}/{{ item._id }}">{{ item.name }}</a></h4>
                <p>{{ item.shortdescription }}</p>
                <p><a class="btn btn-outline-secondary ml-1 mt-2" routerLink="/item/{{ item.category | lowercase }}/{{ item._id }}">Details</a></p>
            </div>
        </div>

    </div>
</div>